import React, {  useEffect, useState } from 'react'

import { Box, Button,  TextField, Typography } from '@mui/material';
import {  socketUrl } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAxios } from '../utils/axiosUtils';
import axios from 'axios';


export const Home = () => {
    const navigate = useNavigate()
    const axiosInstance = useAxios()
    const {handleLogout,user,token,refreshToken} = useAuth()

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
      }[readyState];

    const [mes,setMes] = useState('')

    
    const handleClickSendMessage = () => {
        sendMessage(mes)
    }

    const testApi = () => {
        console.log("token: "+token,'\n refToken: '+refreshToken)

        axiosInstance.get('/api/test')
        .then((resp)=>console.log(resp))
        .catch((e)=>console.error(e))
    }

    useEffect(()=>{console.log('mes',lastMessage)},[lastMessage])

    return (
        <>

        <Box>
            <Button variant='outlined' onClick={()=>navigate('/admin')}>
                Admin
            </Button>
            <Button variant='outlined' onClick={handleLogout}>
                logout
            </Button>

            <span>The WebSocket is currently {connectionStatus}</span>
        </Box>
        <TextField value={mes} onChange={(e)=>setMes(e.target.value)}/>
        <Button
            variant='contained'
            onClick={handleClickSendMessage}
            disabled={readyState !== ReadyState.OPEN}
        >
            Send
        </Button>
        { lastMessage &&
            <Typography>{lastMessage.data}</Typography>}

        <Button onClick={testApi}>Test</Button>

        <Box>
            <Typography>
                {user.email}
            </Typography>
            <Typography>
                {user.username}
            </Typography>
        </Box>
        </>
    )
}
  
/*
const auth = useAuth()
{headers : {
    'content-type': 'application/json',
    ...(auth.token && {
        'Authorization': 'Bearer '+auth.token
    })
}}


*/