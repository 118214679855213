import { Box, Button, Container, FormControl, IconButton, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { axiosClient, useAxios } from '../utils'
import { useQueries } from '@tanstack/react-query';
import { TwoBarContentLoader } from '../component/contentLoading';
import { BottomDrawer } from '../component/menu';
import { useSearchParams } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";
import { FaStar } from "react-icons/fa6"
import { CiStar } from "react-icons/ci"


import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormInput } from '../component/form';
import { priceService } from '../utils/service';
import { useStateContext } from '../context';


export const Trade = () => {
  
  const axiosInstance = useAxios()


  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    {
      queryKey: ['trade'],
      queryFn: () =>   axiosInstance.get('/api/trade'),
      //axiosClient.get('trade'),
    },
    {
      queryKey: ['priceTrade'],
      queryFn: () => axiosClient.get('priceTrade'),
    }
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [wallets,trade,priceTrade] = results

  return (
    <>
    {( wallets.isLoading
      || wallets.data.data === undefined
      || trade.isLoading
      || trade.data.data === undefined
      || priceTrade.isLoading
      || priceTrade.data.data === undefined
      ) && <TwoBarContentLoader/>}

    { wallets.isSuccess
      && wallets.data.data !== undefined
      && trade.isSuccess
      && trade.data.data !== undefined
      && priceTrade.isSuccess
      && priceTrade.data.data !== undefined
      && 
      <TradeLayout wallets={wallets.data.data} trade={trade.data.data} tradeObj = {trade} priceTrade={priceTrade.data.data}/> 
    }
    </>
  )
}

const TradeLayout = ({wallets,trade, tradeObj, priceTrade}) => {

  const axiosInstance = useAxios()

  const {price} = useStateContext()

  //for bottom drawer
  const [state, setState] = useState(false)


  const DEFAULTCRYPTO = trade[0].compatibleWallet[0].tradeId
  const DEFAULTTYPE='buy'


  const [searchParams, setSearchParams] = useSearchParams();
  

  let id = searchParams.get('vr')||DEFAULTCRYPTO
  let type = searchParams.get('tr') ||DEFAULTTYPE

  const [crypto, setCrypto] = useState(id)
  const [typeTransac, setTypeTransac] = useState(type)
  
  const changeId = (newId) => {
    setSearchParams({ vr: newId,tr:typeTransac }); // Update the 'id' parameter in the URL
  }


  useEffect(()=>{
    setSearchParams({ vr:crypto,tr:typeTransac });
  },[typeTransac])



  useEffect(()=>{
  if (id !== null ){
    setCrypto(id)
  } else {
    setCrypto(DEFAULTCRYPTO)
  }

},[])


  const getCurrentCrypto= () => trade
  .map((item) => {
    
    const wallet = item.compatibleWallet.find((wallet) => wallet.tradeId == crypto);
    if (wallet) {
      return {
        id: item.id,
        name: item.name,
        symbol: item.symbol,
        icon: item.icon,
        compatibleWallet: wallet,
      };
    }
    return null;
  })
  .find((item) => item !== null);


  const [currentTrade, setCurrentTrade] = useState({})
  const [currentPriceTrade,setCurrentPriceTrade] = useState({})

  //default crypto 
  useEffect(()=>{
    const res = getCurrentCrypto()
    console.log('mety', res)
    setCurrentTrade(res)
  },[])

  useEffect(()=>{
    changeId(crypto)
    const result = getCurrentCrypto()
    setCurrentTrade(result)
  },[crypto])

  //for any change of 
  useEffect(()=>{
    if(Object.keys(currentTrade).length>0 && Object.keys(price).length>0){
      console.log(priceService(currentTrade.symbol,currentTrade.compatibleWallet.symbol,price,priceTrade))
      setCurrentPriceTrade(priceService(currentTrade.symbol,currentTrade.compatibleWallet.symbol,price,priceTrade))
    }
  },[currentTrade])

  //method to set favorite
  const setFavoriteTrade = () => {
    axiosInstance.post('/api/tradeFavorite/'+currentTrade.compatibleWallet.tradeId)
    .then((resp)=>{tradeObj.refetch()})
    .catch((e)=>{})
  }

  //prix
  const [currentPrice,setPrice] = useState(0)

  useEffect(()=>{
    //console.log('dghgj',currentTrade)
    if(Object.keys(currentPriceTrade).length !== 0){
/////
      if(typeTransac === 'buy'){
        setPrice(currentPriceTrade.buyPrice)
      }
      else{
        setPrice(currentPriceTrade.sellPrice)
      }
    }
  },[typeTransac,currentPriceTrade])

  const dataForm = new FormData()

    const validationSchema = Yup.object().shape({
      solde : Yup.number()
          .typeError("Veuillez entrer un chiffre")
          .required("Veillez ajouter un nombre"),
      eqSolde : Yup.number()
          .typeError("Veuillez entrer un chiffre")
          .required("Veillez ajouter un nombre"),
    })
  
    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: {
            solde: '',
            eqSolde: '',
          },
      })
  
      
    const {handleSubmit,watch,setValue,formState: { errors }} = methods
  
    const solds = watch('solde')
    const [eqSoldeState,setEqSoldeState]=useState('')

    useEffect(() => {
      if (solds) {
        const value = solds * currentPrice
        setEqSoldeState(value);
        setValue('eqSolde', value);
      }
    }, [solds, setValue,currentPrice]);

      const onSubmit = (data) => {
       // setIsLoading(true)
       console.log(data)
  
       
        
      //dataForm.append('solde',data.solde)
      //dataForm.append('eqSolde',data.eqSolde)
      alert('mety')
      
     /* axios.post(ENDPOINT,dataForm,
      {
          headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm => dataForm,//to solve axios issue
      })
      .then(resp => {
          setSnackbar({children:'Creation de nouveau mode de paiement avec success', severity: 'success'})
          setIsLoading(false)
          setStatus(true)
        //navigate('/login')
      })
      .catch(error => {
          setIsLoading(false)
          setSnackbar({children:'Erreur sur la creation de nouveau mode de paiement', severity: 'error'})
          setStatus(true)
          //console.log(error.response.data.message)
          })*/
      }
  

return (
  <>
  <BottomDrawer trade={trade} priceTrade={priceTrade} tradeObj={tradeObj} setCrypto={setCrypto} state={state} setState={setState}/>
  { Object.keys(currentTrade).length > 0 && <Container sx={{ display:'flex',justifyContent:'center'}}>
    
      <Paper sx={{width:{xs:'100%',md:500}, px:{xs:1,md:6}, pb:3, pt:2}}>
          <Stack alignItems={'center'} spacing={1} sx={{}}>
            {/* head of trade */}
            <Box sx={{ width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Box 
                onClick={()=>{setState(true)}}
                sx={{
                  width:'fit-content', 
                  display:'flex',
                  alignItems:'center', 
                  p:1, 
                  mb:1, 
                  borderRadius:1,
                  '&:hover':{
                    backgroundColor:'#ffffff11',
                    cursor:'pointer' 
                  } }}>
                    <Box sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                        
                        <Typography sx={{ml:0.5, fontSize:21}}>{currentTrade.compatibleWallet.symbol}</Typography>
                    </Box>
                    <Typography variant='h5' sx={{fontWeight:''}}>/</Typography>
                    <Box sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                       
                        <Typography sx={{ml:0.5, fontSize:21}}>{currentTrade.symbol}</Typography>
                    </Box>
                    <Box sx={{pr:0.5,pl:1,display:'flex',alignItems:'center'}}>
                      <IoMdArrowDropdown size={25}/>
                    </Box>
                </Box>
                {/* favorite buttton  */}
                <IconButton onClick={setFavoriteTrade}>
                  {currentTrade.compatibleWallet.favorite ? <FaStar size={23} color='yellow'/> : <CiStar size={25}/>}
                </IconButton>
                {/* end favorite buttton */}

            </Box>
            {/* END head of trade */}

              {/* bottom trade */}
              <Box sx={{display:'flex', width:'100%'}}>
                  <Button 
                    variant={typeTransac === 'buy' ? 'contained' : 'outlined'}
                    onClick={()=>setTypeTransac('buy')} 
                    sx={{width:'100%', mr:0.5}}>
                      Acheter
                  </Button>
                  <Button 
                    variant={typeTransac === 'buy' ? 'outlined' : 'contained'} 
                    color='danger'
                    onClick={()=>setTypeTransac('sell')} 
                    sx={{width:'100%', ml:0.5}}>
                      Vendre
                  </Button>
              </Box>
              {/* END bottom trade */}
              <Box sx={{
                bgcolor:'#292828',
                width:'100%',
                display:'flex',
                justifyContent:'center',
                p:1.5
              }}>
                <Typography>Prix ({currentTrade.symbol}) : {typeTransac === 'buy' ? currentPriceTrade.buyPrice:currentPriceTrade.sellPrice} </Typography>
              </Box>

              <FormProvider {...methods}>
              <form>
                  <FormInput sx={{mb:1}} name='solde' label={`Combient de ${currentTrade.compatibleWallet.symbol} voulez vous ${typeTransac === 'buy' ? 'Acheter':'vendre'}?`}  error={!!errors?.solde} helperText={errors?.solde?.message} fullWidth/>
                  <FormInput sx={{mb:1}} value={eqSoldeState} name='eqSolde' label={`Total que vous allez payer en ${currentTrade.symbol}`} error={!!errors?.eqSolde} helperText={errors?.eqSolde?.message} disabled fullWidth/>
                  <Button  
                    variant='contained' 
                    sx={{width:'100%'}} 
                    color={typeTransac === 'buy' ? 'primary' : 'danger'} 
                    onClick={handleSubmit(onSubmit)}>
                      {typeTransac === 'buy' ? 'Acheter' : 'Vendre'}  des {currentTrade.compatibleWallet.symbol}
                  </Button>
              </form>
              </FormProvider>
          </Stack>
      </Paper>
  </Container>}
  </>
)
}
