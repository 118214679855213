import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { TwoBarContentLoader } from '../component/contentLoading';
import { axiosClient, useAxios } from '../utils';
import { useParams } from 'react-router-dom';
import { Box, Button, Container, Grid, Paper, StepContent, Typography } from '@mui/material';
import { CountdownComponent } from '../component/ui';





import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import { MdCheckCircle, MdRadioButtonUnchecked } from 'react-icons/md';
import { Title } from '../component/admin';


const steps = [
    { label: 'Dépôt soumis', subLabel: '08/10/2024, 18:12:34', completed: true },
    { label: 'En cours (24/25)', subLabel: 'Le dépôt sera crédité après 1 confirmation.', completed: true },
    { label: 'Effectué', subLabel: '', completed: false },
  ];


export const TransactionComfirmation = () => {
    const axiosInstance = useAxios()

    const { transactionId } = useParams()

    ///
    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        {
          queryKey: ['apiAccountNumber'],
          queryFn: () => axiosInstance.get('/api/accountNumber'),
        },

        {
            queryKey: ['transaction'+transactionId],
            queryFn: () => axiosInstance.get('api/transaction/'+transactionId),
          },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,paymentMethod,accountNumber,transaction] = results
    
    
    return(
      <>
      {(
          wallets.isLoading
        || wallets.data.data === undefined
        || paymentMethod.isLoading
        || paymentMethod.data.data === undefined
        || accountNumber.isLoading
        || accountNumber.data.data === undefined
        || transaction.isLoading
        || transaction.data.data === undefined
        ) && <TwoBarContentLoader/>}
    
      {  wallets.isSuccess
        && wallets.data.data !== undefined
        && paymentMethod.isSuccess
        && paymentMethod.data.data !== undefined
        && accountNumber.isSuccess
        && accountNumber.data.data !== undefined
        && transaction.isSuccess
        && transaction.data.data !== undefined
        && 
      <TransactionComfirmationLayout 
        wallets={wallets.data.data} 
        paymentMethodData={paymentMethod.data.data}
        accountNumber={accountNumber.data.data}
        transaction={transaction.data.data}
        />
      }
      </>
  )
}

const TransactionComfirmationLayout = ({wallets,paymentMethodData,accountNumber,transaction}) => {
    
    const wallet = wallets.find(w=>w.id===transaction.walletId);
    const pm = paymentMethodData.find(p => p.id === transaction.paymentMethod)

    console.log(transaction)
    const [status,setStatus] = useState('')
    useEffect(()=>setStatus(transaction.status),[])
    console.log(pm)
    return (
      <Container>
        <Grid container>
            <Grid item xs={12} md={6}>
            <Paper sx={{width:{xs:'100%',md:500}, px:{xs:1,md:6}, pb:3, pt:2}}>

            
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Title title={'Depot'}/>
                <Typography variant='h6' sx={{fontWeight:'bold'}}>+{transaction.balanceRequested}</Typography>
            </Box>
            <Box sx={{display:'flex',justifyContent:'center'}}>
            <Stepper activeStep={0} orientation="vertical">
                {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel
                    optional={
                        index === steps.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                        ) : null
                    }
                    >
                    {step.label}
                    </StepLabel>
                    <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                        <Button
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                        >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button
                        disabled={index === 0}
                        sx={{ mt: 1, mr: 1 }}
                        >
                        Back
                        </Button>
                    </Box>
                    </StepContent>
                </Step>
                ))}
            </Stepper>
            </Box>

                { status === 'preProcessing' &&

                <Box sx={{mt:2}}>
                <CountdownComponent transactionId={transaction.transactionId}/>
                
                
                </Box>
                }
            </Paper>
            </Grid>
        </Grid>
      </Container>
    )
  }

/*

  export const TransactionComfirmation = () => {
    const steps = [
      { label: 'Dépôt soumis', subLabel: '08/10/2024, 18:12:34', completed: true },
      { label: 'En cours (24/25)', subLabel: 'Le dépôt sera crédité après 1 confirmation.', completed: true },
      { label: 'Effectué', subLabel: '', completed: false },
    ];
  
    return (
      <Box sx={{ width: '100%', padding: 3 }}>

      </Box>
    );
  };
  */
  
