import React from 'react'
import { Menu, menuClasses, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'
import { LuLayoutDashboard } from "react-icons/lu"
import { IoMenu } from "react-icons/io5"
import { Box, IconButton, Typography } from '@mui/material'
import logo from '../../asset/image/Manavola.webp'
import { CgShoppingBag } from "react-icons/cg"
import { LuUserSquare2 } from "react-icons/lu"
import { BiWalletAlt } from "react-icons/bi"
import { MdOutlineEvent } from "react-icons/md";
import { RiExchangeBoxLine } from "react-icons/ri";
import { Link } from 'react-router-dom'

export const SidebarDrawer = ({toggled, setToggled, collapsed, setCollapsed}) => {

  //const [collapsed, setCollapsed] = useState(false);
  

  const themes = {
    dark: {
      sidebar: {
        backgroundColor: '#1e1e1e',
        color: '#8ba1b7',
      },
      menu: {
        menuContent: '#2b2b2b77',
        icon: '#59d0ff',
        hover: {
          backgroundColor: '#2b2b2b',
          color: '#b6c8d9',
        },
        disabled: {
          color: '#3e5e7e',
        },
      },
    },
  }

  const menuItemStyles = {
    root: {
      fontSize: '16px',
      fontWeight: 400,
    },
    icon: {
      color: themes['dark'].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes['dark'].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: themes['dark'].menu.menuContent,
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes['dark'].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: themes['dark'].menu.hover.backgroundColor,
        color: themes['dark'].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };


  return (
    <>
    <Box sx={{
      position:'fixed',
      zIndex:5,
      height:'100vh'

    }}>
    <Sidebar 
        collapsed={collapsed} 
        onBackdropClick={() => setToggled(false)} toggled={toggled} 
        breakPoint="sm" 
        backgroundColor={themes['dark'].sidebar.backgroundColor}
        rootStyles={{
          color: themes['dark'].sidebar.color,
        }}
        style={{
          height:'100vh'
        }}
    >
    <Box sx={{
        display:'flex',
        alignItems:'center',
        mt:3,
        ml:2.5,
        mb:'10px'
    }}>
        <IconButton onClick={()=>setCollapsed(!collapsed)}>
            <IoMenu />
        </IconButton>
        { ! collapsed && <Typography sx={{ml:1, fontSize:23}}>ADMIN</Typography>}
    </Box>
    <Box sx={{
        display:'flex',
        justifyContent:'center',
    }}>

        { ! collapsed && <Box component={'img'} src={logo} width={70} />}
    </Box>
    <Menu style={{ marginBottom: '24px', marginTop: '10px' }} 
        menuItemStyles={menuItemStyles}
      >

        <MenuItem active component={<Link to={'/admin'}/>} icon={<LuLayoutDashboard  />}>
          Dashboard
        </MenuItem>
        <SubMenu  label="Commande" icon={<CgShoppingBag />}>
          <MenuItem> Pie charts</MenuItem>
          <MenuItem> Line charts</MenuItem>
          <MenuItem> Bar charts</MenuItem>
        </SubMenu>
        <SubMenu label="Utilisateur" icon={<LuUserSquare2 />}>
          <MenuItem> Pie charts</MenuItem>
          <MenuItem> Line charts</MenuItem>
          <MenuItem> Bar charts</MenuItem>
        </SubMenu>
        <SubMenu label="Wallet" icon={<BiWalletAlt  />}>
          <MenuItem  component={<Link to={'wallet'}/>}>
            Les Actifs
          </MenuItem>
          <MenuItem component={<Link to={'paymentMethod'}/>}> Methode de paiement</MenuItem>
          <MenuItem component={<Link to={'category'}/>}> Categories</MenuItem>
        </SubMenu>

        <SubMenu  label="Trade" icon={<RiExchangeBoxLine />}>
          <MenuItem component={<Link to={'trade'}/>} >
            Trade
          </MenuItem>
          <MenuItem component={<Link to={'trade/rate'}/>} >
            Cours
          </MenuItem>
        </SubMenu>
        
        <MenuItem disabled icon={<MdOutlineEvent />}>
          Evenement
        </MenuItem>
        <MenuItem icon={<LuLayoutDashboard />}> Examples</MenuItem>
      </Menu>
      </Sidebar>
      </Box>
      <Box sx={{
        display:{xs:'none',sm:'flex'}
      }}>
        <Sidebar 
          collapsed={collapsed} 
          onBackdropClick={() => setToggled(false)} toggled={toggled} 
          breakPoint="sm" 
          backgroundColor={'transparent'}
          
      >

      </Sidebar>
  </Box>
      </>
  )
}

export const SidebarDrawerFake = ({toggled, setToggled, collapsed, setCollapsed})=>{
return (
  <Sidebar 
        collapsed={collapsed} 
        onBackdropClick={() => setToggled(false)} toggled={toggled} 
        breakPoint="sm" 
        backgroundColor={'transparent'}
        
    >

  </Sidebar>
)
}
