import { Box, Button, Container, Stack, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { WalletShow } from '../component'
import { axiosClient, money, useAxios } from '../utils'
import { useNavigate } from 'react-router-dom'
import { useQueries } from '@tanstack/react-query'
import { TwoBarContentLoader } from '../component/contentLoading'

export const Wallet = () => {
  const [tabActive,setTabActive] = useState('one')

  const axiosInstance = useAxios()

  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    {
      queryKey: ['balance'],
      queryFn: () =>   axiosInstance.get('/api/balance'),
      //axiosClient.get('trade'),
    },
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [wallets, balances] = results

  const handleChangeTab = (e,newValue) =>{
    setTabActive(newValue)
  }
  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabActive} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab value="one" label="Mes Actifs" />
          <Tab value="two" label="Historique" />
        </Tabs>
      </Box>
      { tabActive === 'one' &&
      <>
        
        <>
      {( wallets.isLoading
        || wallets.data.data === undefined
        || balances.isLoading
        || balances.data.data === undefined
        ) && <TwoBarContentLoader/>}

      { wallets.isSuccess
        && wallets.data.data !== undefined
        && balances.isSuccess
        && balances.data.data !== undefined
        && 
        <WalletActif wallets={wallets.data.data} balances={balances.data.data}/> 
      }
      </>
      </>
      }
    </Container>
  )
}

const WalletActif = ({wallets, balances}) => {

  const navigate = useNavigate()

  console.log('b',balances)
  
  return (
    <>
    <Typography mt={3}>
        Total
    </Typography>
    <Typography sx={{
        fontSize:18,
        fontWeight:'bold',
      }}>
        2 359 505 Ar
    </Typography>

    <Box display={'flex'} mt={2}>
      <Button variant='outlined' sx={{mr:1}} onClick={()=>{navigate('/transaction')}}>
        Depot
      </Button>
      <Button variant='outlined' sx={{ml:1}} onClick={()=>{navigate('/transaction/retrait')}}>
        Retrait
      </Button>
    </Box>

    <Typography mt={3}>Les actifs dans mon portefeuille</Typography>
    <Stack spacing={1} mt={3}>
      {
        wallets.map((item,index)=>{
          const balance = balances.find(i => i.walletId === item.id)
          return <WalletShow key={index} img={item.icon} name={item.name} symbol={item.symbol} value={balance.balance}/>
        })
      }
    </Stack>
    </>
  )
}