import { spread } from "axios";

export const priceService = (baseWallet,compatibleWallet,price,priceTrade) => {
    const priceValue = price.find(obj => obj.hasOwnProperty(compatibleWallet))[compatibleWallet];
    //nim
    if(baseWallet === 'USDT'){
        const tm = priceTrade.find(i=>i.pairSymbol=== 'USDT')

        const dtm = (tm.buyPrice+tm.sellPrice)/2
        const buyPrice = tm.buyPrice/dtm*priceValue
        const sellPrice = tm.sellPrice/dtm*priceValue

        return {
            buyPrice: buyPrice,
            sellPrice: sellPrice,
            lastPrice: (buyPrice+sellPrice)/2,
            spread: (buyPrice-sellPrice)*100/buyPrice
        }
    } 
    else if (baseWallet === 'USD'){
        const um = priceTrade.find(i=>i.pairSymbol=== 'USD')
        const dum = (um.buyPrice+um.sellPrice)/2
        const btu = um.buyPrice/dum
        const stu = um.sellPrice/dum

        if (compatibleWallet === 'USDT'){
            return {
                buyPrice: btu,
                sellPrice: stu,
                lastPrice: (btu+stu)/2,
                spread: (btu-stu)*100/btu
            }
        }
        else { //crypto
            const buyPrice = btu*priceValue
            const sellPrice = stu*priceValue

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }
    }
    else if(baseWallet === 'MGA') { //MGA

        if(compatibleWallet === 'USDT'){
            const tm = priceTrade.find(i=>i.pairSymbol=== 'USDT')
            //const dtm = (tm.buyPrice+tm.sellPrice)/2
            const buyPrice = tm.buyPrice
            const sellPrice = tm.sellPrice

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }
        else if (compatibleWallet === 'USD'){
            const um = priceTrade.find(i=>i.pairSymbol=== 'USD')
            const buyPrice = um.buyPrice
            const sellPrice = um.sellPrice

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }
        else { //crypto
            const tm = priceTrade.find(i=>i.pairSymbol=== 'USDT')
            //const dtm = (tm.buyPrice+tm.sellPrice)/2
            const buyPrice = tm.buyPrice*priceValue
            const sellPrice = tm.sellPrice*priceValue

            return {
                buyPrice: buyPrice,
                sellPrice: sellPrice,
                lastPrice: (buyPrice+sellPrice)/2,
                spread: (buyPrice-sellPrice)*100/buyPrice
            }
        }

    }
}